import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  getDetail:[],
  isloader:false
}

export const candidateSlice = createSlice({
  name: 'candidateDetail',
  initialState,
  reducers: {
    fetchingStart: (state) =>{
        state.isloader = true
    },
    getCandidateSuccess: (state,action) => {
        state.getDetail = action.payload
        state.isloader = false
    },
   
    deleteCandidate: (state,action) => {
      state.isloader = true
      state.getDetail = state.getDetail.filter((elem)=> elem._id !== action.payload)
        state.isloader = false
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {fetchingStart, getCandidateSuccess, deleteCandidate} = candidateSlice.actions

export default candidateSlice.reducer