import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users:null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    LoginSuccess: (state,action) => {
        state.users = action.payload
        // state.isLoader = false
    },
    Logout:(state) =>{
        state.users = null
        // state.isLoader = false
    }
    
  },
})

export const { LoginSuccess,  Logout} = userSlice.actions

export default userSlice.reducer