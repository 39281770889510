import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  getblogs:[],
  isloader:false
}

export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    fetchingStart: (state) =>{
        state.isloader = true
    },
    getBlogSuccess: (state,action) => {
        state.getblogs = action.payload
        state.isloader = false
    },
    deleteBlog: (state,action) => {
      state.isloader = true
      state.getblogs = state.getblogs.filter((elem)=> elem._id !== action.payload)
        state.isloader = false
    },
    updateBlog: (state, action) => {
      const index = state.getblogs.findIndex(blog => blog?._id === action.payload?._id);
      if (index !== -1) {
        state.getblogs[index] = action.payload; 
      }
      state.isloader = false;
    }
    
  },
})

// Action creators are generated for each case reducer function
export const {fetchingStart, getBlogSuccess, deleteBlog, updateBlog} = blogSlice.actions

export default blogSlice.reducer