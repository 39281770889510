import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  getReviews:[],
  isloader:false
}

export const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    fetchingStart: (state) =>{
        state.isloader = true
    },
    getReviewsSuccess: (state,action) => {
        state.getReviews = action.payload
        state.isloader = false
    },
    deleteReview: (state,action) => {
      state.isloader = true
      state.getReviews = state.getReviews.filter((elem)=> elem._id !== action.payload)
        state.isloader = false
    },
    updateReview: (state, action) => {
      const index = state.getReviews.findIndex(review => review._id === action.payload._id);
      if (index !== -1) {
        state.getReviews[index] = action.payload; // Update the review with the new data
      }
      state.isloader = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const {fetchingStart, getReviewsSuccess, deleteReview, updateReview} = reviewSlice.actions

export default reviewSlice.reducer